import { useContext } from "react"
import { TravelersContext } from "./travelers.context"

const useTravelers = () => {
  const { state, filters, setFilters } =
    useContext(TravelersContext)

    const setTravelersRange = (value) => {
      setFilters({ ...filters, limit: value },)
    }

    const setTravelersOffset = (value) => {
      setFilters({ ...filters, offset: value },)
    }

    const setTravelersOrderBy = (value) => {
      setFilters({ ...filters, order_by: value },)
    }

    const setTravelersSortBy = (value) => {
      setFilters({ ...filters, sort_by: value },)
    }

  return {
    travelersList: state.travelersList,
    totalTravelers: state.totalTravelers,
    syncing: state.loading,
    error: state.error,
    filters,
    setTravelersRange,
    setTravelersOffset,
    setTravelersOrderBy,
    setTravelersSortBy,
  }
}

export default useTravelers
