import Page from "../page"
import { useHistory } from "react-router-dom";
// import IncidentsFilters from "./incidents.filters"
// import IncidentsPagination from "./incidents.pagination"
import { Button, HList } from "@thetreep/components-library"
import IncidentsList from "./incidents.list"
import IncidentsMap from "./incidents.map";

const Incidents = () => {
    const history = useHistory();
    const handleClick = () => {
        history.push("/newincident")
    }
    return(
        <Page data-testid="page-incidents">
            <Button size="wide" onClick={handleClick}>Créer un incident</Button>
            <HList h="fit-content" p="16px 0 0 0" ai="start">
                <IncidentsList />
                <IncidentsMap />
            </HList>
        </Page> 
    )
}           

// <IncidentsFilters />
// <IncidentsPagination />

export default Incidents
