
import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { HList, IconButton } from "@thetreep/components-library"

const ButtonContainer = styled.div`
  &:not(:last-child) {
    padding-right: 0.5em;
  }
`

const GroupButton = ({ onClick, children, isActive, id, disabled }) => {
  return (
    <ButtonContainer>
      <IconButton
        type={isActive ? "secondary" : "outline"}
        disabled={disabled}
        id={id}
        onClick={() => onClick(id)}
        selected={isActive}
      >
        {children}
      </IconButton>
    </ButtonContainer>
  )
}

/* Will show the right 'tag' within documentation */
GroupButton.displayName = "GroupButton"
GroupButton.defaultProps = {
  disabled: false,
}

GroupButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

const ResultsLimit = ({ ranges, defaultValue, disabled, onChange }) => {
  const [activeState, setActive] = useState(defaultValue)

  const clickHandler = (item, fn) => {
    setActive(item.value)
    fn(item.value)
  }

  return (
    <HList jc="flex-start">
      {ranges.map((range) => {
        return React.createElement(
          GroupButton,
          {
            disabled,
            id: `results-limit-${range.uuid}`,
            key: `results-limit-${range.uuid}`,
            isActive: range.value === activeState,
            onClick: () => clickHandler(range, onChange),
          },
          range.label
        )
      })}
    </HList>
  )
}

/* Will show the right 'tag' within documentation */
ResultsLimit.displayName = "ResultsLimit"
ResultsLimit.defaultProps = {
  disabled: false,
}

ResultsLimit.propTypes = {
  /** List of buttons */
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  /** Selected by default */
  defaultValue: PropTypes.number.isRequired,
  /** Disabled */
  disabled: PropTypes.bool,
}

export default ResultsLimit
