import { useEffect, useState } from "react"
import axios from "axios"
import styled from "styled-components"
import { Card, VList } from "@thetreep/components-library"
import HotelsItem from "./hotels.item"
import {
    useParams
  } from "react-router-dom";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
    align-items: start;
`

/*
<Button onClick={() => warnHotel()} size="large">Vérifier</Button>
    const warnHotel = async () => {
        try{
            await axios.post(`https://trm-thetreep.herokuapp.com/incidents/${}/hotels`)
        } catch(err) {
            console.log(err)
        }
    }
*/

const HotelsList = () => {
    let { id } = useParams();
    const [ hotelsList, setHotelsList ] = useState([])
    const [ load ] = useState(false)

    useEffect(() => {
        const loadHotel = async () => {
            try{
                const result = await axios.get(`https://trm-thetreep.herokuapp.com/incidents/${id}/hotels`)
                setHotelsList(result.data)
            } catch(err) {
                console.log(err)
            }
        } 
       loadHotel()  
    }, [load, id])

    return(
        <Wrapper>
            <Card>
                <VList h="100%">
                    <VList h="fit-content">
                        { hotelsList.length === 0 && <div>Aucun hotel n'a été trouvé dans votre contexte</div>}
                        { hotelsList.length > 0 && hotelsList.map((hotel) => <HotelsItem key={hotel._id} hotel={hotel} />)}
                    </VList>
                </VList>
            </Card>
        </Wrapper>
    )
}

export default HotelsList
