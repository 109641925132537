import styled from "styled-components"
import TravelersToggleOrderBy from "./travelers.toggle.orderby"

const Wrapper = styled.div`
    margin-right: 16px;
`

const TravelersOrderBy = () => {
    return(
        <Wrapper>
            <TravelersToggleOrderBy />
        </Wrapper>
    )
}

export default TravelersOrderBy
