import TravelersAPIProvider from "./travelers.api.provider"

const travelersDefaultStore = {
  travelers: [],
  filters: {
    "limit":10,
    "offset":0,
    "order_by":"ASC",
    "sort_by":"lastname",
  },
  total: 0,
  error: {
    hasError: false,
    message: null,
  },
  api: {
    ...TravelersAPIProvider(
      {
        endpoint: "https://trm-thetreep.herokuapp.com/",
        // endpoint: "http://localhost:9000/",
        env: "staging",
        headers: {},
        services: {
          paxs: "paxs",
        },
      },
    ),
  },
}

export default travelersDefaultStore