import { useState } from "react"
import styled from "styled-components"
import axios from "axios"

const Circle = styled.div`
    width: 10px;
    height: 10px;
    background: #FF0000;
    border-radius: 50%;
`

const Debug = () => {
    const [debug, setDebug] = useState(false)
    axios.interceptors.response.use((response) => response, (error) => {
        setDebug(true)
        return Promise.reject(error);
      });
    return(
        <>
        { debug && <Circle /> }
        </>
    )
}

export default Debug
