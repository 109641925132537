import styled from "styled-components"
import useTravelers from "./travelers.hook"
import { Select } from "@thetreep/components-library/lib/components/atoms"

// Default Select Options
const defaultOptions = [
    {
        label: "Entreprise",
        value: "company",
        uuid: 0,
    },
    {
        label: "Nom",
        value: "lastname",
        uuid: 1,
    },
    {
        label: "Email",
        value: "email",
        uuid: 2,
    },
  ]

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: fit-content;
  align-items: center;
`

const TravelersToggleSortBy = () => {
    const { filters, setTravelersSortBy, syncing } = useTravelers()
    return(
        <Wrapper>
            <Select
                options={defaultOptions}
                placeholder="Choose"
                disabled={syncing}
                active={false}
                name="Sort By"
                isFiltered={true}
                maxItems={5}
                id="travelers-sortby-select"
                defaultValue={defaultOptions.find(options => options.value === filters.sort_by)}
                onChange={(item) => setTravelersSortBy(item.value)}
            />
        </Wrapper>
    )
}

export default TravelersToggleSortBy
