import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortAlphaDown, faSortAlphaUpAlt } from '@fortawesome/free-solid-svg-icons'
import ResultsOrderBy from "../components/molecules/results.orderby"
import useTravelers from "./travelers.hook"

// Default Select Options
const defaultOptions = [
    {
        label: <FontAwesomeIcon icon={faSortAlphaDown} size="xs" />,
        value: "ASC",
        uuid: 0,
    },
    {
        label: <FontAwesomeIcon icon={faSortAlphaUpAlt} size="xs" />,
        value: "DESC",
        uuid: 1,
    },
  ]

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: fit-content;
  align-items: center;
`

const TravelersToggleOrderBy = () => {
    const { filters, setTravelersOrderBy, syncing } = useTravelers()
    return(
        <Wrapper>
            <ResultsOrderBy
                ranges={defaultOptions}
                defaultValue={defaultOptions.find((option) => option.value === filters.order_by).value}  
                onChange={setTravelersOrderBy}
                disabled={syncing}
            />
        </Wrapper>
    )
}

export default TravelersToggleOrderBy
