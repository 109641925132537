import styled from "styled-components"
import useIncidents from "./incidents.hook"
import { Card, VList } from "@thetreep/components-library"
import IncidentsItem from "./incidents.item"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;
    margin-right: 16px;
    align-items: start;
`

/*
<Button onClick={() => warnHotel()} size="large">Vérifier</Button>
    const warnHotel = async () => {
        try{
            await axios.post(`https://trm-thetreep.herokuapp.com/incidents/${incident._id}/ask`)
        } catch(err) {
            console.log(err)
        }
    }
*/

const IncidentsList = () => {
    const { incidentsList } = useIncidents()
    return(
        <Wrapper>
            <Card>
                <VList h="100%">
                    <VList h="fit-content">
                        { incidentsList.length === 0 && <div>Aucun incident n'a été trouvé dans votre contexte</div>}
                        { incidentsList.length > 0 && incidentsList.map((incident) => <IncidentsItem key={incident._id} incident={incident} />)}
                    </VList>
                </VList>
            </Card>
        </Wrapper>
    )
}

export default IncidentsList
