import styled from "styled-components"

const Page = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 16px;
`

export default Page
