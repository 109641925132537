import styled from "styled-components"
import useIncidents from "./incidents.hook"
import { Select } from "@thetreep/components-library/lib/components/atoms"

// Default Select Options
const defaultOptions = [
    {
        label: "label",
        value: "label",
        uuid: 0,
    }
  ]

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: fit-content;
  align-items: center;
`

const IncidentsToggleSortBy = () => {
    const { filters, setIncidentsSortBy, syncing } = useIncidents()
    return(
        <Wrapper>
            <Select
                options={defaultOptions}
                placeholder="Choose"
                disabled={syncing}
                active={false}
                name="Sort By"
                isFiltered={true}
                maxItems={5}
                id="incidents-sortby-select"
                defaultValue={defaultOptions.find(options => options.value === filters.sort_by)}
                onChange={(item) => setIncidentsSortBy(item.value)}
            />
        </Wrapper>
    )
}

export default IncidentsToggleSortBy
