import { useContext } from "react"
import { IncidentsContext } from "./incidents.context"

const useIncidents = () => {
  const { state, filters, setFilters } =
    useContext(IncidentsContext)

    const setIncidentsRange = (value) => {
      setFilters({ ...filters, limit: value },)
    }

    const setIncidentsOffset = (value) => {
      setFilters({ ...filters, offset: value },)
    }

    const setIncidentsOrderBy = (value) => {
      setFilters({ ...filters, order_by: value },)
    }

    const setIncidentsSortBy = (value) => {
      setFilters({ ...filters, sort_by: value },)
    }

  return {
    incidentsList: state.incidentsList,
    totalIncidents: state.totalIncidents,
    syncing: state.loading,
    error: state.error,
    filters,
    setIncidentsRange,
    setIncidentsOffset,
    setIncidentsOrderBy,
    setIncidentsSortBy,
  }
}

export default useIncidents
