import React, { useState, useReducer, useEffect } from "react"
import PropTypes from "prop-types"

// Create context for profil
const TravelersContext = React.createContext([{}, () => {}])

// Strategy pattern rather than switch case
const cases = { 
  "FETCH_REINIT": (payload) =>  { 
    return { incidentID: payload.incidentID }
  },
  "FETCH_INIT": () =>  { 
    return { loading: true, error: { hasError: false, message: null } }
  },
  "FETCH_SUCCESS": (payload) =>  { 
    return { loading: false, error: { hasError: false, message: null }, totalTravelers: payload.totalTravelers, travelersList: payload.travelersList }
  },
  "FETCH_FAILURE": (payload) =>  {
    return { loading: false, error: { hasError: true, message: payload } }
  },
}

// reducer
const dataFetchReducer = (state, action) => {
  const updatedState = cases[action.type](action.payload)
  return { ...state, ...updatedState }
};

const TravelersProvider = ({ children, initialStore }) => {
  const [state, dispatch] = useReducer(dataFetchReducer, { 
    totalTravelers: initialStore.total,
    travelersList: initialStore.travelers,
    incidentID: initialStore.incidentID || null,
    loading: false,
    error: initialStore.error 
  })

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    dispatch({ type: 'FETCH_REINIT', payload: { incidentID: urlParams.get("id") } })
  }, [])

  const [filters, setFilters] = useState(initialStore.filters)

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' })
      try {
        const travelers = await initialStore.api.getTravelers(filters, state.incidentID)
        dispatch({ type: 'FETCH_SUCCESS', payload: { travelersList: travelers.data.result, totalTravelers: travelers.data.total } })
      } catch (error) {
        dispatch({ type: 'FETCH_FAILURE' })
      }
    }
    fetchData()

    let id = setInterval(() => {
      fetchData()
    }, 6000);
    return () => clearInterval(id);

  }, [filters, initialStore.api, state.incidentID])
  
  return (
    <TravelersContext.Provider
      value={{
        state,
        dispatch,
        filters,
        setFilters
      }}
    >
      {children}
    </TravelersContext.Provider>
  )
}

TravelersProvider.displayName = "TravelersProvider"
TravelersProvider.propTypes = {
  /** Children */
  children: PropTypes.element.isRequired,
}

export { TravelersContext, TravelersProvider }
