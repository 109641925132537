import styled from "styled-components"
import axios from "axios"
import { Button } from "@thetreep/components-library"
import { useState } from "react"

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    height: 48px;
    padding: 8px;
    width: 100%;
    &:not(:last-child) {
        margin-bottom: 0.5em;
        border-bottom: 1px dashed #333333;
    }
    border-radius: 4px;
    align-items: center;
`

const Cell = styled.div`
    min-width: ${({ mw }) => mw};
    width: ${({ w }) => w};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: ${({ c }) => c};
    height: 32px;
    margin-right: 16px;
`

const colors = { OK: "#80c904", KO: "#FF0000", pending: "#ff6600" }

const TravelersItem = ({ user }) => {
    const [warn, setWarn] = useState({ sending: false, sended: false})
    const warnUser = async () => {
        setWarn({ sending: true, sended: false})
        try{
            await axios.post(`https://trm-thetreep.herokuapp.com/paxs/${user._id}`)
            setWarn({ sending: false, sended: true})
        } catch(err) {
            setWarn({ sending: false, sended: false})
            console.log(err)
        }
    }

    return(
        <Wrapper>
            <Cell w="100%">{`${user.lastname} ${user.firstname}`}</Cell>
            <Cell w="100%">{`${user.email}`}</Cell>
            <Cell w="5%" c={colors[user.status]} />
            <Button onClick={warnUser} size="large" type="secondary" loading={warn.sending} disabled={warn.sended}>{ warn.sended ? "Envoyé" : "Prévenir"}</Button>
        </Wrapper>
    )
}

export default TravelersItem
