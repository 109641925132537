import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import styled from "styled-components"

// Wrapper in order to center everything within page
const Wrapper = styled.div`
  display: flex;
  width: 48px;
  height: 24px;
  margin-left: 32px;
  justify-content: center;
  align-items: center;
`

const Spinner = ({ id }) => {
    return(
        <Wrapper>
          <FontAwesomeIcon icon={faSpinner} pulse data-testid={id} />  
        </Wrapper>
    )
}

/* Will show the right 'tag' within documentation */
Spinner.displayName = "Spinner"
Spinner.defaultProps = {
    id: "spinner",
}

Spinner.propTypes = {
  id: PropTypes.string,
}

export default Spinner
