import axios from "axios"

const travelersAPIProvider = (configuration, token) => {
  // Retrieve all travelers for a given tenant
  const getTravelers = (filters, incidentID) =>
    axios.get(`${configuration.endpoint}${configuration.services.paxs}/incidents/${incidentID}`, {
      headers: {},
      params: {
        ...filters,
      }
    })

  return {
    getTravelers,
  }
}

export default travelersAPIProvider