import styled from "styled-components"
import useTravelers from "./travelers.hook"
import { Card, VList } from "@thetreep/components-library"
import TravelersItem from "./travelers.item"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
    align-items: start;
`

const TravelersList = () => {
    const { travelersList } = useTravelers()
    return(
        <Wrapper>
            <Card>
                <VList h="100%">
                    <VList h="fit-content">
                        { travelersList.length === 0 && <div>Aucun utilisateur n'a été trouvé dans votre contexte</div>}
                        { travelersList.length > 0 && travelersList.map((user) => <TravelersItem key={user.uid} user={user} />)}
                    </VList>
                </VList>
            </Card>
        </Wrapper>
    )
}

export default TravelersList
