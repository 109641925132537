import styled from "styled-components"
import { useHistory } from "react-router-dom";
import { Button } from "@thetreep/components-library"

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    height: 48px;
    padding: 8px;
    width: 100%;
    &:not(:last-child) {
        margin-bottom: 0.5em;
        border-bottom: 1px dashed #333333;
    }
    border-radius: 4px;
    align-items: center;
`

const Cell = styled.div`
    min-width: ${({ mw }) => mw};
    width: ${({ w }) => w};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

const IncidentsItem = ({ incident }) => {
    const history = useHistory();
    const handleClick = () => {
        history.push(`/hotels/${incident._id}`)
    }
    return(
        <Wrapper>
            <Cell w="50%">{`${incident.attributes.name}`}</Cell>
            <Cell w="20%">{`${incident.attributes.address}`}</Cell>
            <Cell w="30%">{`${incident.attributes.city}`}</Cell>
            <Cell w="30%">{`${incident.status}`}</Cell>
            <Button onClick={handleClick} size="large">Voir</Button>
        </Wrapper>
    )
}

export default IncidentsItem
