import styled from "styled-components"
import { useHistory } from "react-router-dom";
import { Button } from "@thetreep/components-library"

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    height: 48px;
    padding: 8px;
    width: 100%;
    &:not(:last-child) {
        margin-bottom: 0.5em;
        border-bottom: 1px dashed #333333;
    }
    border-radius: 4px;
    align-items: center;
`

const Cell = styled.div`
    min-width: ${({ mw }) => mw};
    width: ${({ w }) => w};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: ${({ c }) => c};
    height: 32px;
    margin-right: 16px;
`

const HotelsItem = ({ hotel }) => {
    const history = useHistory();
    const showPaxs = async () => {
        try{
            // Correspond à l'id nécessaire pour remonter les paxs
            // Représente un hôtel en particulier sur lequel sont "attachés" des paxs
            history.push(`/paxs?id=615d82b24add200b10a2611e`) 
        } catch(err) {
            console.log(err)
        }
    }

    return(
        <Wrapper>
            <Cell w="100%">{`${hotel.attributes.name}`}</Cell>
            <Cell w="100%">{`${hotel.attributes.address}`}</Cell>
            <Cell w="100%">{`${hotel.attributes.city}`}</Cell>
            <Button onClick={showPaxs} size="large">Paxs</Button>
        </Wrapper>
    )
}

// <Cell w="5%" c={colors[hotel.status]} />

export default HotelsItem
