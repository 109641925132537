import TravelersToggleLimit from "./travelers.toggle.limit"
import TravelersTogglePagination from "./travelers.toggle.pagination"
import { HList, Card} from "@thetreep/components-library"

const TravelersPagination = () => {
    return(
        <Card overflow="unset">
            <HList jc="space-between" w="100%" data-testid="travelers-pagination" >
                <TravelersToggleLimit />
                <TravelersTogglePagination />
            </HList>
        </Card>
    )
}

export default TravelersPagination
