import styled from "styled-components"

const CardBody = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

export default CardBody
