import axios from "axios"

const incidentsAPIProvider = (configuration, token) => {
  // Retrieve all travelers for a given tenant
  const getIncidents = (filters) =>
    axios.get(`${configuration.endpoint}${configuration.services.incidents}`, {
      headers: {},
    })

  return {
    getIncidents,
  }
}

export default incidentsAPIProvider