import Page from "../page"
import TravelersFilters from "./travelers.filters"
import TravelersPagination from "./travelers.pagination"
import TravelersList from "./travelers.list"
import TravelersHeader from "./travelers.header"

const Travelers = () => {
    return(
        <Page data-testid="page-travelers">
            <TravelersHeader />
            <TravelersFilters />
            <TravelersList />
            <TravelersPagination />
        </Page> 
    )
}

export default Travelers
