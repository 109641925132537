import React from 'react'
import { GoogleMap, LoadScript, Circle } from '@react-google-maps/api';
import useIncidents from "./incidents.hook"

const center = {
    lat: 43.8,
    lng: 5.31
  };
  
  const circleOptions = {
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1
  }

  const containerStyle = {
    width: '100%',
    height: '300px',
  };

const IncidentsMap = () => {
    const { incidentsList } = useIncidents()
    const colors = { terminated: "#80c904", inprogress: "#ff6600", pending: "#FF0000" }
    return(
          <LoadScript
            googleMapsApiKey="AIzaSyCF1x18QFVaFPIiYcf6gbEl143TUJyxWOI"
          >
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={6}
            >
              { incidentsList.map(incident => {
                return(<Circle
                  key={incident._id}
                  center={{
                    lat: incident.attributes.location.latitude,
                    lng: incident.attributes.location.longitude
                  }}
                  // required
                  options={
                    {
                      ...circleOptions,
                      radius: incident.attributes.location.radius,
                      fillColor: colors[incident.status],
                      strokeColor: colors[incident.status],
                    }
                  }
                />)
              })}
            </GoogleMap>
          </LoadScript>
    )
}

export default React.memo(IncidentsMap)