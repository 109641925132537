import styled from "styled-components"
import axios from "axios"
import { Title, HList, Button } from "@thetreep/components-library"
import { useHistory, useLocation } from "react-router";
import Debug from "../components/debug";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 128px;
    padding: 32px 16px 16px 16px;
`

const Header = () => {
    const location = useLocation();
    const history = useHistory();
    const reInit = async () => {
        try{
            await axios.get(`https://trm-thetreep.herokuapp.com/init`)
            history.replace("/")
        } catch(err) {
            alert("Une erreure s'est produite")
        }
    }

    return(
        <Wrapper>
            <HList h="fit-content" jc="start">
                <Debug />
                <div onClick={reInit}>
                    <Title ellipsis>ARGOS</Title>
                </div>
            </HList>
            <HList w="210px">
                <Button
                    type={location.pathname === '/incidents' || location.pathname === '/newincident' ? 'secondary' : 'outline'}
                    onClick={() => history.push('/incidents')}
                    children="Incidents"
                />
                <Button
                    type={location.pathname === '/paxs' ? 'secondary' : 'outline'}
                    onClick={() => history.push('/paxs')}
                    children="Voyageurs"
                />
            </HList>
        </Wrapper>
    )
}

export default Header
