import { useEffect, useState } from "react"
import styled from "styled-components"
import { Title } from "@thetreep/components-library"
import ResultsPagination from "../components/molecules/results.pagination"
import useTravelers from "./travelers.hook"

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: fit-content;
  align-items: center;
`

const paginate = (currentPage, lastPage, delta = 2) => {
    // creates array with base 1 index
    const range = Array(lastPage)
      .fill()
      .map((_, index) => index + 1);
  
    return range.reduce((pages, page) => {
      // allow adding of first and last pages
      if (page === 1 || page === lastPage) {
        return [...pages, page];
      }
  
      // if within delta range add page
      if (page - delta <= currentPage && page + delta >= currentPage) {
        return [...pages, page];
      }
  
      // otherwise add 'gap if gap was not the last item added.
      if (pages[pages.length - 1] !== '...') {
        return [...pages, '...'];
      }
  
      return pages;
    }, []);
  };

const TravelersTogglePagination = () => {
    const { filters, totalTravelers, setTravelersOffset, syncing } = useTravelers()
    const [pages, setPages] = useState([])

    // Create pagination each time totalTravelers, filters.limit a,nd filters.offset change
    useEffect(() => {
        // Create array of data to match component props
        const p = Array(Math.ceil(totalTravelers / filters.limit)).fill(0).map((c, index) => { 
            return { 
                uuid: index,
                label: index + 1,
                value: index,
            }
        })

        // If there is more than 2 pages we update `pages` state accordingly
        if (p.length >= 2) {
            const n = paginate(filters.offset, p.length - 1, 2)
            setPages(n.map((item) => {
                return { ...p[item -1], label: item, disabled: item === "..." };
              }))
          }

    }, [totalTravelers, filters.limit, filters.offset])

    return(
        <>
        { pages.length > 0 && 
           ( <Wrapper>
                <Title size="h4">Pages</Title>
                <ResultsPagination
                    ranges={pages}
                    defaultValue={pages.find((page) => page.value === filters.offset).value}
                    onChange={setTravelersOffset}
                    disabled={syncing}
                />
            </Wrapper>)
        }
        </>
    )
}

export default TravelersTogglePagination
