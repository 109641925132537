import React, { useState, useReducer, useEffect } from "react"
import PropTypes from "prop-types"

// Create context for profil
const IncidentsContext = React.createContext([{}, () => {}])

// Strategy pattern rather than switch case
const cases = { 
  "FETCH_INIT": () =>  { 
    return { loading: true, error: { hasError: false, message: null } }
  },
  "FETCH_SUCCESS": (payload) =>  { 
    return { loading: false, error: { hasError: false, message: null }, totalIncidents: payload.totalIncidents, incidentsList: payload.incidentsList }
  },
  "FETCH_FAILURE": (payload) =>  {
    return { loading: false, error: { hasError: true, message: payload } }
  },
}

// reducer
const dataFetchReducer = (state, action) => {
  const updatedState = cases[action.type](action.payload)
  return { ...state, ...updatedState }
};

const IncidentsProvider = ({ children, initialStore }) => {
  const [state, dispatch] = useReducer(dataFetchReducer, { 
    totalIncidents: initialStore.total,
    incidentsList: initialStore.incidents,
    loading: false,
    error: initialStore.error 
  })

  const [filters, setFilters] = useState(initialStore.filters)

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' })
      try {
        const incidents = await initialStore.api.getIncidents(filters)
        dispatch({ type: 'FETCH_SUCCESS', payload: { incidentsList: incidents.data.result || [], totalIncidents: incidents.data.total || 0 } })
      } catch (error) {
        dispatch({ type: 'FETCH_FAILURE' })
      }
    }
    fetchData()

  }, [filters, initialStore.api])

  
  return (
    <IncidentsContext.Provider
      value={{
        state,
        dispatch,
        filters,
        setFilters
      }}
    >
      {children}
    </IncidentsContext.Provider>
  )
}

IncidentsProvider.displayName = "IncidentsProvider"
IncidentsProvider.propTypes = {
  /** Children */
  children: PropTypes.element.isRequired,
}

export { IncidentsContext, IncidentsProvider }
