import styled from "styled-components"
import TravelersToggleSortBy from "./travelers.toggle.sortby"

const Wrapper = styled.div`
    width: 128px;
`

const TravelersSortBy = () => {
    return(
        <Wrapper>
            <TravelersToggleSortBy />
        </Wrapper>
    )
}

export default TravelersSortBy
