import { HList, Card } from "@thetreep/components-library"
import TravelersOrderBy from "./travelers.orderby"
import TravelersSortBy from "./travelers.sortby"
import useTravelers from "./travelers.hook"
import Spinner from "../components/atoms/spinner"

const TravelersFilters = () => {
    const { totalTravelers, syncing } = useTravelers()
    return( 
        <Card overflow="unset">
            <HList jc="space-between" w="100%" data-testid="travelers-filters">
                <HList jc="flex-start" w="fit-content">
                    <TravelersOrderBy />
                    <TravelersSortBy />
                </HList>
                <HList>
                    <div>{`${totalTravelers} utilisateurs ont été trouvés`}</div>
                    {syncing && <Spinner id="travelers-spinner" />}
                </HList>
            </HList>
        </Card>
    )
}

export default TravelersFilters
