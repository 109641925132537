import Page from "../page"
import HotelsList from "./hotels.list"

const Hotels = () => {
    return(
        <Page data-testid="page-hotels">
            <HotelsList />
        </Page> 
    )
}           

// <IncidentsFilters />
// <IncidentsPagination />

export default Hotels
