import { useState } from "react"
import styled from "styled-components"
import { Switch, Route, Redirect } from "react-router-dom";
import { ThemeProvider, theme } from "@thetreep/components-library"
import Header from "./header/header"
import Form from './form'
import { Travelers, TravelersProvider, travelersDefaultStore } from "./travelers"
import { Incidents, IncidentsProvider, incidentsDefaultStore } from "./incidents"
import Hotels from "./hotels/hotels";

// Catch url params
const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const id = urlParams.get("id")

// Wrapper in order to center everything within page
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const App = () => {
  const [state] = useState({
    theme,
    travelersDefaultStore,
    incidentsDefaultStore
  })
  return(
    <Wrapper data-testid="app">
      <ThemeProvider initialStore={state.theme}>
          <Header />
          <Switch>
              <Route path="/paxs">
                <TravelersProvider initialStore={{...state.travelersDefaultStore, incidentID: id}}>
                  <Travelers />
                </TravelersProvider>
              </Route>
              <Route path="/incidents">
                <IncidentsProvider initialStore={state.incidentsDefaultStore}>
                  <Incidents />
                </IncidentsProvider>
              </Route>
              <Route path="/hotels/:id">
                <Hotels />
              </Route>
              <Route path="/newincident">
                <Form />
              </Route>
              <Route path="/">
                <Redirect to="/incidents" />
              </Route>
          </Switch>
      </ThemeProvider>
    </Wrapper>
  )
}

export default App;
