import styled from "styled-components"
import { Title } from "@thetreep/components-library"
import ResultsLimit from "../components/molecules/results.limit"
import useTravelers from "./travelers.hook"

// Default Select Options
const defaultOptions = [
    {
        label: "10",
        value: 10,
        uuid: 0,
    },
    {
        label: "20",
        value: 20,
        uuid: 1,
    },
    {
        label: "30",
        value: 30,
        uuid: 2,
    },
  ]

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: fit-content;
  align-items: center;
`

const TravelersToggleLimit = () => {
    const { filters, setTravelersRange, syncing } = useTravelers()
    return(
        <Wrapper>
            <Title size="h4">Résultats par page</Title>
            <ResultsLimit
                ranges={defaultOptions}
                defaultValue={defaultOptions.find((option) => option.value === filters.limit).value}  
                onChange={setTravelersRange}
                disabled={syncing}
            />
        </Wrapper>
    )
}

export default TravelersToggleLimit
