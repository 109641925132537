import styled from "styled-components"
import IncidentsToggleOrderBy from "./incidents.toggle.orderby"

const Wrapper = styled.div`
    margin-right: 16px;
`

const IncidentsOrderBy = () => {
    return(
        <Wrapper>
            <IncidentsToggleOrderBy />
        </Wrapper>
    )
}

export default IncidentsOrderBy
