import IncidentsAPIProvider from "./incidents.api.provider"

const incidentsDefaultStore = {
  incidents: [],
  filters: {
    "limit":10,
    "offset":0,
    "order_by":"ASC",
    "sort_by":"uuid",
  },
  total: 0,
  error: {
    hasError: false,
    message: null,
  },
  api: {
    ...IncidentsAPIProvider(
      {
        endpoint: "https://trm-thetreep.herokuapp.com/",
        // endpoint: "http://localhost:9000/",
        env: "staging",
        headers: {},
        services: {
          incidents: "incidents",
        },
      },
    ),
  },
}

export default incidentsDefaultStore