import styled from "styled-components"
import IncidentsToggleSortBy from "./incidents.toggle.sortby"

const Wrapper = styled.div`
    width: 128px;
`

const IncidentsSortBy = () => {
    return(
        <Wrapper>
            <IncidentsToggleSortBy />
        </Wrapper>
    )
}

export default IncidentsSortBy
