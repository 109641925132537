
import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { HList, IconButton } from "@thetreep/components-library"

const ButtonContainer = styled.div`
  &:not(:last-child) {
    padding-right: 0.5em;
  }
`

const GroupButton = ({ onClick, children, isActive, id, disabled }) => {
  return (
    <ButtonContainer>
      <IconButton
        type={isActive ? "secondary" : "outline"}
        disabled={disabled}
        id={id}
        onClick={() => onClick(id)}
        selected={isActive}
      >
        {children}
      </IconButton>
    </ButtonContainer>
  )
}

/* Will show the right 'tag' within documentation */
GroupButton.displayName = "GroupButton"
GroupButton.defaultProps = {
  disabled: false,
}

GroupButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

const buildItem =  (item, state, handler, callback, disabled) => {
  if (item.label !== "...") return React.createElement(
    GroupButton,
    {
      disabled: disabled,
      id: `results-pagination-${item.uuid}`,
      key: `results-pagination-${item.uuid}`,
      isActive: item.value === state,
      onClick: () => handler(item, callback),
    },
    item.label
  )
  if (item.label === "...") return <ButtonContainer><IconButton
      type="textonly"
      disabled={true}
      id="unclickable"
    >
      {item.label}
    </IconButton></ButtonContainer>
}

const ResultsPagination = ({ ranges, defaultValue, disabled, onChange }) => {
  const [activeState, setActive] = useState(defaultValue)

  const clickHandler = (item, fn) => {
    setActive(item.value)
    fn(item.value)
  }

  return (
    <HList jc="flex-start">
      {ranges.map((range) => {
        return buildItem(range, activeState, clickHandler, onChange, disabled)
        }
      )}
    </HList>
  )
}

/* Will show the right 'tag' within documentation */
ResultsPagination.displayName = "ResultsPagination"
ResultsPagination.defaultProps = {
  disabled: false,
}

ResultsPagination.propTypes = {
  /** List of buttons */
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  /** Selected by default */
  defaultValue: PropTypes.number.isRequired,
  /** Disabled */
  disabled: PropTypes.bool,
  /** Callback triggered on click */
  onChange: PropTypes.func.isRequired
}

export default ResultsPagination
